<template>
  <div>
    <div class="SearchBox">
      <div class="row justify-content-around">
        <div
          v-if="getUserRole('data_public')"
          class="SearchBox-btn"
          :class="searchType ? 'borderType' : ''"
        >
          <a
            class="SearchBox-btn-1 font-w550"
            :style="searchType ? { color: '#3a85fd' } : ''"
            @click="clickSearch(true)"
            type="text"
            >公众公司查询</a
          >
        </div>

        <div
          v-if="getUserRole('data_nonpublic')"
          class="SearchBox-btn"
          :class="!searchType ? 'borderType' : ''"
        >
          <a
            class="SearchBox-btn-1 font-w550"
            :style="!searchType ? { color: '#3a85fd' } : ''"
            @click="clickSearch(false)"
            type="text"
            >非公众公司查询</a
          >
        </div>
      </div>
      <div class="searchForm">
        <div class="mt-3 position-relative">
          <div class="row justify-content-between">
            <div class="color-5d font-w550 fz-16">
              公司名称<span class="color-f49"> * </span>
              <el-tooltip
                v-show="!searchType"
                class="item"
                effect="dark"
                content="如未查询到相关企业名称，请点击企业管理"
                placement="bottom-start"
              >
                <i class="el-icon-warning-outline color-1f"></i>
              </el-tooltip>
            </div>
            <div class="color-1f" v-show="!searchType">
              <a @click="$router.push('database/data-manager')">企业管理</a>
            </div>
          </div>
          <div class="mt-1">
            <el-input
              v-if="searchType"
              type="text"
              style="width: 100%"
              placeholder="请输入公司名称、股票代码"
              id="inputText"
              v-model="searchText"
              @keyup.native="queryCompany"
            />
            <el-input
              v-else
              type="text"
              style="width: 100%"
              id="inputText"
              placeholder="请输入公司名称关键词"
              v-model="searchText"
              @focus="searchShowFn(0)"
              @click.stop="searchShowFn(0)"
              @keyup.native="qCu"
            />
            <div
              class="position-absolute search-list"
              v-if="searchShow && searchType == true"
              @click.stop="searchShowFn(0)"
            >
              <template v-for="(item, index) in searchLists">
                <a
                  href="javascript:void(0)"
                  v-if="index < 8"
                  :key="index"
                  class="d-block mt-2 search-items"
                  style="display: flex !important; align-items: center"
                  :class="{ ch: index == keyDownNows }"
                  @mouseover="mouseClearStyle"
                  @click.stop="stepToCompanyInfo(item)"
                >
                  <span
                    class="color-4d search-hot-title d-inline-block text-center"
                    v-if="item.publish_type != '04'"
                    >股票</span
                  >
                  <span
                    class="color-4d search-hot-title d-inline-block text-center"
                    v-if="item.publish_type == '04'"
                    >债券</span
                  >
                  <span
                    class="ml-4 fz-14 text-center fontA"
                    style="width: 55px"
                    >{{ item.S_or_B_code }}</span
                  >
                  <span class="color-4d ml-10 fz-14 text-center">
                    {{ item.companyInfo.name }}</span
                  >
                </a>
              </template>
            </div>

            <div
              class="position-absolute search-list"
              v-show="searchText != '' && searchTypes == false"
            >
              <template v-for="(item, index) in searchLists">
                <a
                  href="javascript:void(0)"
                  v-if="index < 8"
                  :key="index"
                  class="d-block mt-2 search-items"
                  @mouseover="mouseClearStyle"
                  @click="stepToCustomInfo(item)"
                  style="display: flex !important; align-items: center"
                >
                  <span
                    class="color-4d search-hot-title d-inline-block text-center"
                    >公司</span
                  >
                  <span
                    class="color-4d ml-4 fz-14"
                    style="
                      width: 251px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                      white-space: nowrap;
                    "
                    >{{ item.name }}</span
                  >
                  <span
                    class="color-4d fz-14 text-center"
                    style="
                      position: relative;
                      width: 200px;
                      display: inline-block;
                    "
                    >({{ item.create_time }})</span
                  >
                </a>
              </template>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="color-5d font-w550 fz-16">
            <span v-show="category == 'gb'">国标行业选择：</span>
            <span v-show="category == 'sw'">申万行业选择：</span>
            <span class="color-f49"> * </span>
          </div>
          <div v-show="category == 'gb'" class="mt-1">
            <selectTree
              :search="search"
              ref="selectTreegb"
              isName="gb"
              :isGb="true"
            />
          </div>
          <div v-show="category == 'sw'" class="mt-1">
            <selectTree
              :search="search"
              ref="selectTreesw"
              isName="sw"
              :isSw="true"
            />
          </div>
        </div>

        <div class="mt-3">
          <div class="color-5d font-w550 fz-16">
            报表年份<span class="color-f49"> * </span>
          </div>
          <div class="mt-1">
            <el-select
              style="width: 100%"
              v-model="form_data.year"
              id="endYear"
            >
              <el-option-group label="截至年份">
                <el-option
                  v-for="year in sortYear"
                  :key="year"
                  :value="year"
                  :label="year"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
        <div class="mt-3">
          <div class="color-5d font-w550 fz-16">
            报表期间<span class="color-f49"> * </span>
          </div>
          <div class="mt-1">
            <el-select style="width: 100%" v-model="form_data.report_time">
              <el-option-group label="报告日期">
                <el-option
                  v-for="(item, report_time) in reasonReport"
                  :key="report_time"
                  :value="report_time"
                  :label="reasonReportMapping[report_time]"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
        <div class="mt-3">
          <div class="color-5d font-w550 fz-16">
            报表种类<span class="color-f49"> * </span>
          </div>
          <div class="mt-1">
            <el-select
              style="width: 100%"
              v-model="form_data.report_type"
              class="br_4 cursor_pionter"
              id="report_type"
            >
              <el-option-group label="报告类型">
                <el-option
                  v-for="report_type in report_type_arr"
                  :key="report_type"
                  :value="report_type"
                  class="cursor_pionter"
                  :label="report_type_mapping[report_type]"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
        <div class="mt-4 text-center">
          <el-button type="primary" style="width: 40%" @click="clickAnalyse"
            >点击分析</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";

import { mapGetters, mapState, mapActions } from "vuex";

import { tjINdustryList, yearList, checkpoint } from "@/api";
export default {
  data() {
    return {
      // ------------
      // 公司搜索操作
      searchType: true,
      searchTypes: true,
      companyType: "0",
      searchLists: [],
      searchText: "",
      mySearchText: "",
      searchShow: false,
      timer: null,

      // ---------
      // 年份期间操作
      // popoverValue: false,
      company_code: "",
      availableYears: [],
      reasonReport: [],
      reasonReportMapping: {
        "00": "年报",
        "01": "三季报",
        "02": "半年报",
        "03": "一季报",
        "001": "一月报",
        "002": "二月报",
        "003": "三月报",
        "004": "四月报",
        "005": "五月报",
        "006": "六月报",
        "007": "七月报",
        "008": "八月报",
        "009": "九月报",
        "010": "十月报",
        "011": "十一月报",
        "012": "十二月报",
      },

      category: "sw",

      report_type_mapping: {
        "00": "合并报表",
        "01": "本部报表",
      },
      date_tree: [],
      report_type_arr: [],
      form_data: {
        year: "",
        report_type: "",
        report_time: "",
        industry_code: "",
      },
      UseDefualt: false,

      company: {},
      // company_type: sesstion.getLocalData("company").company_type,
    };
  },
  components: {
    selectTree: require("@/components/selectTree/selectTree.vue").default,
  },
  created() {},
  mounted() {
    window.listDebt = this;
    let type = this.getUserRole("data_public");
    if (type) {
      this.clickSearch(type);
    } else {
      this.clickSearch(type);
    }
  },
  methods: {
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),
    clickSearch(type) {
      this.searchType = type;
      this.searchTypes = type;
      this.searchText = "";
      this.date_tree = [];
      this.availableYears = [];
      this.form_data.year = "";
      this.form_data.report_type = "";
      this.form_data.report_time = "";
      if (type) {
        this.category = "sw";
      } else {
        this.category = "gb";
      }
    },
    qCu() {
      !!this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(this.queryCustom, 1000);
    },
    queryCustom() {
      var value = this.searchText;
      if (value != "") {
        this.$http
          .get(
            window.BASE_URL + "/company/my/search/" + value + "/" + 0 + "/",
            {
              headers: {
                Authorization:
                  "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
              },
            }
          )
          .then(
            (res) => {
              this.searchLists = res.data;
              this.searchTypes = false;
            },
            (res) => {
              // console.log("请求失败！");
            }
          );
      }
    },
    queryCompany() {
      clearTimeout(this.timer);

      this.timer = setTimeout(this.qc, 1000);
    },

    qc() {
      var value = this.searchText;
      this.search_val = this.searchText;
      if (value != "") {
        if (this.checkNumber(value)) {
          this.$http
            .get(
              window.BASE_URL + "/company/listed/search-by-stock/" + value + "/"
            )
            .then(
              (res) => {
                this.searchLists = res.data;
                if (this.searchLists.errMessage == "没有查询到结果！") {
                  this.searchShow = false;
                  return this.$message.error(res.data.errMessage);
                } else {
                  this.searchShow = true;
                }
              },
              (res) => {
                this.searchShow = false;
              }
            );
        } else {
          this.$http
            .get(
              window.BASE_URL + "/company/listed/search-by-name/" + value + "/"
            )
            .then(
              (res) => {
                this.searchLists = res.data;
                if (this.searchLists.errMessage == "没有查询到结果！") {
                  this.searchShow = false;
                  return this.$message.error(res.data.errMessage);
                } else {
                  this.searchShow = true;
                }
              },
              (res) => {
                this.searchShow = false;
              }
            );
        }
      } else {
        this.searchShow = false;
      }
    },

    searchShowFn(i) {
      if (i == 0) {
        if (
          this.searchText == "" ||
          this.searchLists.errMessage == "没有查询到结果！"
        ) {
          this.searchShow = false;
        } else {
          this.searchShow = true;
        }
      } else {
        this.searchShow = false;
      }
    },
    checkNumber(theObj) {
      let reg = /^([A-Z]|[0-9])+.?([A-Z]|[0-9])*$/;
      if (reg.test(theObj)) {
        return true;
      }
      return false;
    },

    // 上市公司列表点击事件
    stepToCompanyInfo(item) {
      // 每次重新搜索公司置空选择参数
      this.form_data.year = "";
      this.form_data.report_type = "";
      this.form_data.report_time = "";
      this.form_data.industry_code = "";

      if (item.is_financial)
        return this.$Message.warning(
          "该企业是金融企业，请进入金融机构分析主题"
        );

      if (item.companyInfo.SWAI_code == "")
        return this.$Message.warning("该企业是退市企业，无法分析");

      let param = {
        company_code: item.company_code,
        report_time: "",
        report_type: "",
        report_year: "",
      };

      sesstion.setLocalData("rowList", param);

      yearList(param).then(({ data }) => {
        if (data.Status == 2) {
          this.searchText = item.companyInfo.name;
          this.searchShow = false;
          this.company = item;
          sesstion.setLocalData("company", item);
          sessionStorage.back_name = this.$route.name; //前版本面包用

          // 下拉选择项
          this.date_tree = data.date_tree;
          this.availableYears = data.Result;
          // this.form_data.year = data.Result[data.Result.length - 1];

          // 获取公司基本信息行业值
          tjINdustryList({
            statistics_code: item.companyInfo.statistics_code || "",
            industry_code: item.companyInfo.SWAI_code || "",
            CSRC_code: item.companyInfo.CSRC_code || "",
          })
            .then((res) => {
              console.info("tjINdustryList", res);

              sesstion.setLocalData("industryData", res.data);

              this.$refs.selectTreesw.industryData = res.data;
              this.$refs.selectTreesw.setSwDefualtTitle();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this.$message.error(data.Message);
        }
      });
      return false;
    },

    // 非上市公司点击
    stepToCustomInfo(item) {
      // 每次重新搜索公司置空选择参数
      this.form_data.year = "";
      this.form_data.report_type = "";
      this.form_data.report_time = "";
      this.form_data.industry_code = "";

      let param = {
        company_code: item.company_code,
        report_time: "",
        report_type: "",
        report_year: "",
      };

      sesstion.setLocalData("rowList", param);

      yearList(param).then(({ data }) => {
        if (data.Status == 2) {
          this.searchText = item.name;
          this.searchTypes = true;
          sesstion.setLocalData("company", item);
          this.company = item;
          sessionStorage.back_name = this.$route.name; //前版本面包用

          // 下拉选择项
          this.date_tree = data.date_tree;
          this.availableYears = data.Result;
          // this.form_data.year = data.Result[data.Result.length - 1];

          // 获取公司基本信息行业值
          tjINdustryList({
            statistics_code: item.statistics_code || "",
            industry_code: item.SWAI_code || "",
            CSRC_code: item.CSRC_code || "",
          })
            .then((res) => {
              console.info("tjINdustryList", res);
              sesstion.setLocalData("industryData", res.data);
              this.$refs.selectTreegb.industryData = res.data;
              this.$refs.selectTreegb.setGbDefualtTitle();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this.$message.error(data.Message);
        }
      });
      return false;
    },

    clickAnalyse() {
      // 跳转前的公共变量操作
      if (this.searchText == "")
        return this.$message.error("请填写完整内容后进行分析操作！");
      if (this.form_data.year == "")
        return this.$message.error("请填写完整内容后进行分析操作！");

      let data = {
        year: this.form_data.year,
        report_year: this.form_data.year,
        report_type: this.form_data.report_type,
        report_time: this.form_data.report_time,
        industry_code:
          this.$refs[`selectTree${this.category}`].get_industry_code(),
        industry_name: this.$refs[`selectTree${this.category}`].get_f_title(),
        company_code: this.company.company_code,
        // name: this.company.companyInfo.name || this.company.name,
        stock_code: this.company.S_or_B_code || "",
      };
      let paras = {
        company_code: this.company.company_code,
        industry_number:
          this.$refs[`selectTree${this.category}`].get_industry_code(),
        industry_range: "0",
        industry_type: this.$refs[`selectTree${this.category}`].get_f_title(),
        menu_id: "1",
        parsing_time: this.form_data.year,
        report_type: this.form_data.report_type,
        report_time: this.form_data.report_time,
      };

      checkpoint(paras).then((res) => {
        if (res.data.Status == "0") {
          this.$alert(res.data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          sesstion.setLocalData("companyData", data);
          // 跳转分析
          this.$router.push({
            name: "CompanyDetail",
            // query: { name: item.companyInfo.name },
          });
        }
      });
    },

    // ------------------------------
    // 以下为年份期间和种类操作
    wath_trigger(wath_trigger_name) {
      this[wath_trigger_name] && this[wath_trigger_name]();
      return false;
    },
    selectedYear_change() {
      // 设置有数据的报告日期

      this.reasonReport = this.date_tree[this.form_data.year];
      for (const key in this.reasonReport) {
        if (this.reasonReport.hasOwnProperty(key)) {
          this.form_data.report_time = this.UseDefualt
            ? this.$store.state.listingParame.report_time
            : key;
          if (this.reasonReport[key]) {
            this.report_time_change();
          } else {
            this.report_type_arr = [];
            this.form_data.report_type = "";
          }

          break;
        }
      }
    },
    get_data() {
      return datas;
    },
    report_time_change() {
      // 设置有数据的报告类型

      this.report_type_arr =
        this.date_tree[this.form_data.year][this.form_data.report_time];

      this.form_data.report_type = this.UseDefualt
        ? this.$store.state.listingParame.report_type
        : this.date_tree[this.form_data.year][this.form_data.report_time][0];
    },
  },
  computed: {
    ...mapState({
      checkoutSearchData: (state) => state.search.checkoutSearchData,
    }),
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),
    list: function () {
      return this.searchLists.filter((v) => {
        return (
          v.stockCode.match(this.searchText) || v.of.match(this.searchText)
        );
      });
    },
    listLength: function () {
      return this.list.length - 1;
    },

    // 年份排序
    sortYear() {
      return (this.availableYears && this.availableYears.reverse()) || [];
    },
  },

  watch: {
    "form_data.year": function () {
      this.wath_trigger("selectedYear_change");
    },
    "form_data.report_time": function () {
      this.wath_trigger("report_time_change");
    },
  },
};
</script>
<style lang="less">
.SearchBox {
  width: 100%;
  height: 600px;
  box-shadow: 0 0 10px #ccc;
  background: rgba(#fff, 0.5);
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 40px;

  .SearchBox-btn {
    width: 200px;
    text-align: center;
    border-bottom: 2px solid #999;
    .SearchBox-btn-1 {
      display: inline-block;
      padding: 5px;
      width: 200px;
      font-size: 20px;
      color: #999;
    }
  }
  .borderType {
    border-bottom: 2px solid #3a85fd;
  }
  .search-hot-title {
    color: #1270ff;
    width: 60px;
    height: 26px;
    line-height: 26px;
    background-color: #c4dbfe;
    border-radius: 20px;
  }
  .search-items {
    padding-left: 10px;
  }
}
.searchForm {
  margin-top: 20px;
}
.search-list {
  left: 0;
  top: 75px;
  width: 100%;
  padding: 10px 30px !important;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
}

.check-items {
  width: 130px;
  height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 550;
  text-align: center;

  // .el-select .el-input__inner {
  //   font-size: 16px;
  //   font-weight: 550;
  //   border: none;
  // }

  // .el-select .el-input .el-select__caret {
  //   height: 40px;
  // }
}

.history {
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #596178;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
}

.fontA {
  font-family: ArialMT;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}

.fontPF {
  font-family: PingFangSC-Regular;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}

.add-company {
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #3a85fd;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
}

.ch {
  background-color: #ddd;
}

@media (max-width: 460px) {
  #inputText {
    font-size: 14px;
  }

  .search_s {
    font-size: 14px;
  }
}
</style>
